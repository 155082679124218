import { Navigate, createFileRoute } from "@tanstack/react-router";

import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";

export const Route = createFileRoute("/admin/")({
  component: Index,
});

function Index() {
  const isSuperUser = useIsSuperUserSelector();

  if (isSuperUser) {
    return <Navigate to="/admin/home" />;
  }
  return <Navigate to="/admin/subscriptions" />;
}
